import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["locationStatus"]
  static values = {
    visitId: String,
    hasLocation: Boolean
  }

  connect() {
    // If this visit doesn't have location data, try to get it
    if (!this.hasLocationValue) {
      this.fetchLocation();
    }
  }

  fetchLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.updateVisitLocation(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.warn("Error getting location:", error);
          if (this.hasLocationStatusTarget) {
            this.locationStatusTarget.style.display = "none";
          }
        }
      );
    } else {
      if (this.hasLocationStatusTarget) {
        this.locationStatusTarget.style.display = "none";
      }
    }
  }

  updateVisitLocation(latitude, longitude) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    
    fetch(`/visits/${this.visitIdValue}/update_location`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        latitude: latitude,
        longitude: longitude
      })
    })
    .then(response => {
      if (response.ok) {
        if (this.hasLocationStatusTarget) {
          // Show a brief success indicator then hide
          this.locationStatusTarget.classList.add("success");
          this.locationStatusTarget.innerHTML = "✓";
          
          // Hide the success message after a few seconds
          setTimeout(() => {
            this.locationStatusTarget.style.display = "none";
          }, 2000);
        }
      } else {
        throw new Error('Failed to update location');
      }
    })
    .catch(error => {
      console.error('Error updating location:', error);
      if (this.hasLocationStatusTarget) {
        this.locationStatusTarget.style.display = "none";
      }
    });
  }
}
