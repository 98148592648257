export default class StructureTreeService {
  static async fetchChildren(structureId) {
    try {
      const url = `/setup/structures/${structureId}/children`;
      console.log("Fetching from URL:", url);
      
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const html = await response.text();
      console.log("Received HTML length:", html.length);
      console.log("First 100 chars of HTML:", html.substring(0, 100));
      
      return {
        success: true,
        html: html
      };
    } catch (error) {
      console.error("Error loading children:", error);
      return {
        success: false,
        error: error.message
      };
    }
  }
  
  static async prefetchChildren(structureId) {
    try {
      const url = `/setup/structures/${structureId}/children`;
      console.log("Prefetching from URL:", url);
      
      const response = await fetch(url);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const html = await response.text();
      console.log("Prefetched HTML length:", html.length);
      
      return {
        success: true,
        html: html
      };
    } catch (error) {
      console.error("Error prefetching children:", error);
      return {
        success: false,
        error: error.message
      };
    }
  }
  
  static sortNodesByName(container) {
    const nodes = Array.from(container.children);
    nodes.sort((a, b) => {
      const aText = a.querySelector('div')?.dataset?.structureTreeStructureNameValue || '';
      const bText = b.querySelector('div')?.dataset?.structureTreeStructureNameValue || '';
      return aText.localeCompare(bText, undefined, { sensitivity: 'base' });
    });
    
    container.innerHTML = "";
    nodes.forEach(node => container.appendChild(node));
  }
  
  static sortAssignmentsByName(container) {
    const assignments = Array.from(container.children);
    assignments.sort((a, b) => {
      const aText = a.querySelector('.assignment-title')?.innerHTML || '';
      const bText = b.querySelector('.assignment-title')?.innerHTML || '';
      return aText.localeCompare(bText, undefined, { sensitivity: 'base' });
    });
    
    container.innerHTML = "";
    assignments.forEach(assignment => container.appendChild(assignment));
  }
}
