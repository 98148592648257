import { Controller } from "@hotwired/stimulus"
import StructureTreeService from "../services/structure_tree_service"

export default class extends Controller {
  static targets = ["icon", "newArea", "newInspectionFormAssignment", "newChecklistAssignment", "collapse", "node", "link", "trashable", "checklist", "inspectionForms", "children"];
  static values = {
    url: String,
    newAreaUrl: String,
    destroyAreaUrl: String,
    createInspectionFormAssignmentUrl: String,
    createChecklistAssignmentUrl: String,
    structureId: Number,
    site: Boolean,
    loaded: { type: Boolean, default: false },
    hasHoverListener: { type: Boolean, default: false }
  }

  connect() {
    console.log("Structure tree controller connected", {
      structureId: this.structureIdValue,
      isSite: this.siteValue,
      hasChildrenElement: !!this.element.querySelector('.children')
    });
    
    this.expanded = false;
    this.selected = false;

    if (this.siteValue) {
      console.log("This is a site, expanding automatically");
      this.expandUI();
      // Don't set loadedValue=true here - we need to fetch children first
      this.loadRightPane();
      this.select();
    
      // Fetch children for the site structure immediately
      this.fetchChildren();
    }

    // Add hover event for prefetching
    if (!this.siteValue && !this.hasHoverListenerValue) {
      console.log("Adding hover listener for prefetching");
      this.element.addEventListener('mouseenter', this.prefetchChildren.bind(this), { once: true });
      this.hasHoverListenerValue = true;
    }

    window.addEventListener('oqc:unselectAll', (event) => {
      if (event.detail.structureId !== this.structureIdValue) {
        this.unselect();
      }
    });
  }

  // This method toggles the icon when the collapse is expanded or hidden
  collapse() {
    console.log("Collapsing UI for", this.structureIdValue);
    try {
      // Use direct DOM manipulation to hide the collapse element
      this.collapseTarget.style.display = "none";
      this.expanded = false;
      this.iconTarget.classList.remove("fa-angle-down");
      this.iconTarget.classList.add("fa-angle-right");
      console.log("Collapsed UI successfully");
    } catch (error) {
      console.error("Error in collapse:", error);
    }
  }

  expandUI() {
    console.log("Expanding UI for", this.structureIdValue);
    console.log("Collapse target:", this.collapseTarget);
    
    try {
      // Try using direct DOM manipulation instead of Bootstrap's collapse
      this.collapseTarget.style.display = "block";
      this.expanded = true;
      this.iconTarget.classList.remove("fa-angle-right");
      this.iconTarget.classList.add("fa-angle-down");
      console.log("Expanded UI successfully");
    } catch (error) {
      console.error("Error in expandUI:", error);
    }
  }

  expand(event) {
    console.log("Expand called for structure", this.structureIdValue);
    if (!this.loadedValue && !this.siteValue) {
      console.log("Need to fetch children first");
      this.fetchChildren();
    } else {
      console.log("Already loaded, just expanding UI");
      this.expandUI();
    }
  }

  async fetchChildren() {
    if (!this.loadedValue) {
      this.iconTarget.classList.add("fa-spin");
      
      // If we already prefetched the HTML, use it
      if (this._prefetchedHtml) {
        this.insertChildrenHtml(this._prefetchedHtml);
        this._prefetchedHtml = null;
      } else {
        const result = await StructureTreeService.fetchChildren(this.structureIdValue);
        
        if (result.success) {
          this.insertChildrenHtml(result.html);
        } else {
          this.iconTarget.classList.remove("fa-spin");
        }
      }
    } else {
      console.log("Children already loaded, just expanding");
      this.expandUI();
    }
  }
  
  // Helper method to insert HTML
  insertChildrenHtml(html) {
    // Check if the children target exists
    if (!this.childrenTarget) {
      console.error("Children target not found!");
      return;
    }
    
    // Try to insert the HTML
    try {
      this.childrenTarget.innerHTML = html;
      console.log("HTML inserted successfully");
      console.log("Children target now contains:", this.childrenTarget.children.length, "children");
      
      this.loadedValue = true;
      this.iconTarget.classList.remove("fa-spin");
      this.expandUI();
      
      // Sort children after loading
      if (this.siteValue) {
        this.sortChildren();
      }
    } catch (error) {
      console.error("Error inserting HTML:", error);
      this.iconTarget.classList.remove("fa-spin");
    }
  }

  selectTrashable(event) {
    this.deselectAllTrashable();
    event.currentTarget.classList.add("active")
  }

  deselectAllTrashable() {
    const structureTrees = this.application.controllers.filter(controller =>
      controller.identifier === this.identifier
    );

    structureTrees.forEach(controller => {
      controller.trashableTargets.forEach(target =>
        target.classList.remove("active")
      )
    }
    )
  }

  select() {
    this.selected = true
    this.nodeTarget.classList.add("active");
    this.linkTarget.style.color = "#fff";
  }

  unselect() {
    this.selected = false
    this.nodeTarget.classList.remove("active");
    this.linkTarget.style.color = "black";
  }

  unselectAll() {
    const evt = new CustomEvent('oqc:unselectAll', {
      detail: {
        structureId: this.structureIdValue
      }
    })
    window.dispatchEvent(evt)
  }

  // primary click handler
  toggleIcon(event) {
    event.preventDefault(); // Prevent default link behavior
    console.log("Toggle icon called", {
      expanded: this.expanded,
      selected: this.selected,
      loadedValue: this.loadedValue,
      siteValue: this.siteValue
    });
    
    if (this.expanded) {
      if (this.selected) {
        console.log("Collapsing selected node");
        this.collapse();
        this.unselect();
        this.unselectAll();
      } else {
        console.log("Selecting expanded node");
        this.select();
        this.unselectAll();
        this.loadRightPane();
      }
    } else {
      if (!this.loadedValue && !this.siteValue) {
        console.log("Fetching children for unexpanded node");
        this.fetchChildren();
      } else {
        console.log("Expanding already loaded node");
        this.expandUI();
      }
      this.select();
      this.unselectAll();
      this.loadRightPane();
    }
  }

  loadRightPane(event) {
    fetch(this.urlValue)
      .then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        // Replace the content of the contentContainer with the response
        document.querySelector("#right-pane").innerHTML = html;

        // Put the modal at the top level of the document
        var modal = document.getElementById('qr-code-modal');
        if (modal) {
          var container = document.getElementById('modal-container');
          container.innerHTML = '';
          container.appendChild(modal);
        }
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  getFormToggleController(element) {
    return this.application.getControllerForElementAndIdentifier(element, 'form-toggle')
  }

  createArea(event) {
    const formController = this.getFormToggleController(this.newAreaTarget)
    const areaName = formController.getValue()?.trim()
    if (!areaName) {
      return
    }
    const params = {
      structure: {
        parent_id: this.structureIdValue,
        name: areaName
      }
    }

    event.preventDefault();
    fetch(this.newAreaUrlValue, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(params)
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        var liElement = document.createElement('li')
        liElement.innerHTML = html
        var ulElement = this.element.querySelector('ul.children')
        ulElement.prepend(liElement)
        this.sortChildren();
        formController.reset();
        if (this.hasNewInspectionFormAssignmentTarget) {
          this.newInspectionFormAssignmentTarget.setAttribute('hidden', '')
        }
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });

  }

  sortChildren() {
    StructureTreeService.sortNodesByName(this.childrenTarget);
  }

  sortChecklists() {
    StructureTreeService.sortAssignmentsByName(this.checklistsTarget);
  }

  sortInspectionForms() {
    StructureTreeService.sortAssignmentsByName(this.inspectionFormsTarget);
  }

  createInspectionFormAssignment(event) {
    event.preventDefault();

    const params = {
      inspection_forms_structures: {
        structure_id: this.structureIdValue,
        inspection_form_id: this.getFormToggleController(this.newInspectionFormAssignmentTarget).getValue()
      }
    }

    fetch(this.createInspectionFormAssignmentUrlValue, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(params)
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        var tempElement = document.createElement('div')
        tempElement.innerHTML = html
        var ulElement = this.element.querySelector('ul.inspection-forms')
        ulElement.prepend(tempElement.firstElementChild)
        this.sortInspectionForms();
        if (this.hasNewAreaTarget) {
          this.newAreaTarget.setAttribute('hidden', '')
        }
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  destroyInspectionFormAssignment(event) {
    const url = event.currentTarget.dataset.destroyInspectionFormAssignmentUrl
    const elementToRemove = event.currentTarget.dataset.elementToRemove

    fetch(url, {
      method: 'DELETE'
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        document.querySelector(elementToRemove).remove()
        if (this.inspectionFormsTarget.children.length === 0) {
          this.newAreaTarget.removeAttribute('hidden')
        }
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  createChecklistAssignment(event) {
    event.preventDefault();

    const params = {
      checklist_assignments: {
        structure_id: this.structureIdValue,
        checklist_id: this.getFormToggleController(this.newChecklistAssignmentTarget).getValue()
      }
    }

    fetch(this.createChecklistAssignmentUrlValue, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(params)
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        var tempElement = document.createElement('div')
        tempElement.innerHTML = html
        var ulElement = this.element.querySelector('ul.checklists')
        ulElement.prepend(tempElement.firstElementChild)
        this.sortAssignments(ulElement);
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  destroyChecklistAssignment(event) {
    const url = event.currentTarget.dataset.destroyChecklistAssignmentUrl
    const elementToRemove = event.currentTarget.dataset.elementToRemove

    fetch(url, {
      method: 'DELETE'
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        document.querySelector(elementToRemove).remove()
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  async prefetchChildren() {
    console.log("Prefetch children called for", this.structureIdValue);
    if (!this.loadedValue && !this.siteValue) {
      // Only prefetch if we have children to load
      const hasChildren = this.iconTarget.classList.contains("fa-angle-right");
      console.log("Has children icon?", hasChildren);
      
      if (hasChildren) {
        const result = await StructureTreeService.prefetchChildren(this.structureIdValue);
        
        if (result.success) {
          this._prefetchedHtml = result.html;
        }
      }
    }
  }
}
